<template>
  <div class="administrator">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>管理员列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="box">
      <span>用户名:</span>
      <el-input
        v-model.trim="list.userName"
        placeholder="请输入用户名"
      ></el-input>

      <span>手机号码:</span>
      <el-input
        v-model.trim="list.userPhone"
        placeholder="请输入手机号码"
      ></el-input>
      <span>真实姓名:</span>
      <el-input
        v-model.trim="list.userTrueName"
        placeholder="请输入真实姓名"
      ></el-input>
      <span>角色:</span>
      <el-select
        v-model="list.roleId"
        filterable
        placeholder="请选择"
        clearable
        @change="onRoleIdChange"
      >
        <el-option
          v-for="item in roleList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="onSearch">查找</el-button>
    </div>
    <el-table
      style="width: 100%"
      border
      :data="tableData"
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" type="selection"></el-table-column>
      <el-table-column
        prop="userId"
        label="角色ID"
        width="50"
      ></el-table-column>
      <el-table-column align="center" prop="userName" label="用户名">
      </el-table-column>
      <el-table-column align="center" prop="userTrueName" label="真实姓名">
      </el-table-column>
      <el-table-column align="center" label="性别" prop="userSex">
        <template slot-scope="{ row }">
          <span v-if="row.userSex === 1">男</span>
          <span v-else>女</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="userPhone" label="手机号码">
      </el-table-column>
      <el-table-column align="center" label="角色状态" prop="userDs">
        <template slot-scope="{ row }">
          <span v-if="row.userDs === 0">存在</span>
          <span v-else>删除</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="userManageRoleId" label="角色名称">
        <template slot-scope="{ row }">
          {{ RoleName(row) }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="userCreateId" label="创建人id">
      </el-table-column>
      <el-table-column align="center" prop="userCreateName" label="创建人名称">
      </el-table-column>
      <el-table-column
        align="center"
        prop="userCreateTime"
        width="200"
        label="创建时间"
      >
      </el-table-column>

      <el-table-column align="center" prop="address" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            v-if="
              $store.state.powerList.indexOf('system:admin:list:update') !== -1
            "
            type="primary"
            @click="$router.push(`/editAdmin/${row.userId}`)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            v-if="
              $store.state.powerList.indexOf(
                'system:admin:list:reset_password'
              ) !== -1
            "
            type="primary"
            @click="$router.push(`/UpdateAdminPassword/${row.userId}`)"
            >修改密码</el-button
          >
          <el-button
            size="mini"
            v-if="
              $store.state.powerList.indexOf('system:admin:list:delete') !== -1
            "
            type="primary"
            @click="onDel(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-button
      class="btn"
      v-if="$store.state.powerList.indexOf('system:admin:list:delete') !== -1"
      type="primary"
      @click="onDelAllAdmin"
      >批量删除</el-button
    >
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { administratorList, delAdmin } from "../../api/administrator";
import { roleList } from "../../api/role";
export default {
  name: "AdministratorList",
  data() {
    return {
      searchWords: "",
      tableData: [],
      value: [],
      delValue: [],
      options: [],
      addDialogVisible: false,
      roleList: null,
      roleName:'',
      pagination:{},
      list: {
        userName: "",
        userTrueName: "",
        userPhone: "",
        roleId: null,
        currentPage: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getAdministratorList();
    this.getRoleList();
    // this.RoleName()
  },
  computed: {},
  methods: {
    RoleName(row) {
      if (this.roleList !== null) {
        const list = this.roleList.filter((item) => {
          return item.roleId === row.userManageRoleId;
        });
        console.log(list,'list')
        // try{
          return list[0].roleName;
        // }catch{
          // return '';
        // }
      }
    },
    async getRoleList() {
      const { data } = await roleList({
        currentPage: 1,
        pageSize: 1000,
      });
      data.list.map((item) => {
        item.label = item.roleName;
        item.value = item.roleId;
      });
      this.roleList = data.list;
    },
    async getAdministratorList() {
      const { data } = await administratorList(this.list);
      this.tableData = data.list;
      this.pagination = data.pagination
      console.log(data);
    },
    onDel(row) {
      console.log(row.userId);
      this.$confirm("是否删除这个角色?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.delValue.push(row.userId);
          const { data } = await delAdmin({
            userIdListStr: this.delValue,
          });
          this.getAdministratorList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSearch() {
      this.getAdministratorList();
    },
    onRoleIdChange(value) {
      this.list.roleId = value;
    },
    handleSelectionChange(val) {
      var arr = [];
      val.map((item) => {
        arr.push(item.userId);
      });
      this.delValue = arr;
    },
    async onDelAllAdmin() {
      if (this.delValue.length <= 0) {
        this.$message({
          message: "请选择要删除的内容",
          type: "warning",
        });
        return;
      }
      const { data } = await delAdmin({
        userIdListStr: this.delValue,
      });
      this.delValue = [];
      console.log(data);
    },
    onClear() {
      console.log(12321);
    },
    handleSizeChange(size) {
      this.list.pageSize = size;
      this.getAdministratorList();
    },
    handleCurrentChange(current) {
      this.list.currentPage = current;
      this.getAdministratorList();
    },
  },
};
</script>
<style lang="less" scoped>
.administrator {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .box {
    // margin: 50px 0 20px;
    // margin-top: 50px;
    span {
      margin: 0 20px 0 40px;
    }
    .el-cascader {
      margin-left: 20px;
    }
    .el-input {
      width: 200px;
    }
    .el-button {
      margin-left: 40px;
      height: 40px;
    }
  }

  .el-table {
    margin-top: 50px;
  }
  .el-button {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>